import GetAGif from '../GetAGif';
import './style.css'

function Giphy() {

    return (
        <img className="giphyImg"src={GetAGif()}></img> 
    )
}

export default Giphy

